import './lesson.scss';

import Info from '../exercises/info';
import CardAtPosition from '../exercises/cardAtPosition';
import PositionOfCard from '../exercises/positionOfCard';
import CardAfter from '../exercises/cardAfter';
import CardBefore from '../exercises/cardBefore';

import { useEffect, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { HandThumbsUp, HandThumbsDown } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import * as LessonUtil from '../../util/lessonUtils';

function Lesson(props) {
	const [currentExercise, setCurrentExercise] = useState(0);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showFail, setShowFail] = useState(false);
	const [exercises, setExercises] = useState([]);
	const [wrongCount, setWrongCount] = useState(0);

	useEffect(() => {
		let stateExercises = [];

		if (props.lesson.exercises) {
			stateExercises = [...props.lesson.exercises];
		} else {
			stateExercises = LessonUtil.generateLessonExercises(
				props.lesson.number
			);
			console.log(stateExercises);
		}

		setExercises(stateExercises);
	}, ['']);

	function renderExercise() {
		if (showSuccess) {
			return (
				<div className='successWrapper'>
					<HandThumbsUp color='#4DA167' size='100' />
					<div className='resultMessage'>Nice job!</div>
				</div>
			);
		}
		if (showFail) {
			return (
				<div className='failWrapper'>
					<HandThumbsDown color='#FF0022' size='100' />
					<div className='resultMessage'>Oh no!</div>
				</div>
			);
		}
		let currentExerciseObject = exercises[currentExercise];
		switch (currentExerciseObject?.type) {
			case 'info':
				return (
					<Info
						exercise={currentExerciseObject}
						onFinish={nextExercise}
					/>
				);
			case 'cardAtPosition':
				return (
					<CardAtPosition
						exercise={currentExerciseObject}
						onCorrect={nextExercise}
						onWrong={wrongAnswer}
					/>
				);
			case 'positionOfCard':
				return (
					<PositionOfCard
						exercise={currentExerciseObject}
						onCorrect={nextExercise}
						onWrong={wrongAnswer}
						maxKnown={props.progress.lessonsCompleted * 4}
					/>
				);
			case 'cardAfter':
				return (
					<CardAfter
						exercise={currentExerciseObject}
						onCorrect={nextExercise}
						onWrong={wrongAnswer}
					/>
				);
			case 'cardBefore':
				return (
					<CardBefore
						exercise={currentExerciseObject}
						onCorrect={nextExercise}
						onWrong={wrongAnswer}
					/>
				);
			default:
				return (
					<Info
						exercise={currentExerciseObject}
						onFinish={nextExercise}
					/>
				);
		}
	}

	function nextExercise() {
		setShowSuccess(true);
		setTimeout(() => {
			setCurrentExercise(currentExercise + 1);
			setShowSuccess(false);
		}, 750);
	}

	function wrongAnswer() {
		let failedExercise = exercises[currentExercise];
		let newExercises = [...exercises];
		setWrongCount(wrongCount + 1);
		setShowFail(true);
		if ('vibrate' in navigator) {
			// vibration API supported
			navigator.vibrate(500);
		}
		setTimeout(() => {
			newExercises.push(failedExercise);
			setExercises(newExercises);
			setCurrentExercise(currentExercise + 1);
			setShowFail(false);
		}, 750);
	}

	function renderProgressBar() {
		let totalExercises = exercises.length;
		let completed = currentExercise;
		return (
			<ProgressBar
				completed={completed}
				maxCompleted={totalExercises}
				isLabelVisible={false}
				barContainerClassName='progressContainer'
				bgColor='#4DA167'
			/>
		);
	}

	function renderComplete() {
		let resultMessage = 'You got a perfect score!';
		let additionalMessage = 'Way to go!';
		if (wrongCount > 0 && wrongCount < 3) {
			resultMessage = 'You only got ' + wrongCount + ' wrong.';
			additionalMessage = 'Time to move on to the next lesson!';
		} else if (wrongCount > 2) {
			resultMessage = 'You got ' + wrongCount + ' wrong.';
			additionalMessage =
				'You need to get less than 3 wrong to unlock the next lesson.';
		}

		return (
			<div className='completed'>
				<div className='lessonHeader'>
					<div className='lessonTitle'>
						<div>Lesson {props.lessonNumber}</div>
					</div>
					<div className='lessonProgress'>{renderProgressBar()}</div>
				</div>
				<div>
					<div className='completedWrapper'>
						<HandThumbsUp color='#4DA167' size='100' />
						<div className='completedText'>Lesson Complete!</div>
						<div className='resultText'>{resultMessage}</div>
						<div className='additionalText'>
							{additionalMessage}
						</div>
					</div>
				</div>
				<div className='exerciseButtons d-grid gap-2'>
					<Button
						variant='primary'
						size='lg'
						onClick={() => props.onComplete(wrongCount < 3)}
					>
						{wrongCount > 2 ? 'Ok' : 'Nice!'}
					</Button>
				</div>
			</div>
		);
	}

	return (
		props.lesson &&
		(exercises.length === currentExercise ? (
			renderComplete()
		) : (
			<div className='lesson'>
				<div className='lessonHeader'>
					<div className='lessonTitle'>
						<div>Lesson {props.lesson.number}</div>
						<div>
							<CloseButton onClick={props.onClose} />
						</div>
					</div>
					<div className='lessonProgress'>{renderProgressBar()}</div>
				</div>
				<div>{renderExercise()}</div>
			</div>
		))
	);
}

export default Lesson;
