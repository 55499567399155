import './exercise.scss';
import stacksData from '../../data/stacks.json';
import * as util from '../../util/cardTranslation';
import * as arrayUtil from '../../util/arrayUtils';
import Button from 'react-bootstrap/Button';

function PositionOfCard(props) {
	function renderCardImage() {
		let imgFile = util.getImageFileName(
			stacksData.mnemonica[props.exercise.position - 1]
		);
		return (
			<img
				alt=''
				key={imgFile}
				src={require('../../images/' + imgFile)}
			/>
		);
	}

	function renderOptions() {
		let correctOption = (
			<Button variant='outline-dark' onClick={props.onCorrect} size='lg'>
				{props.exercise.position}
			</Button>
		);

		let wrongPositions = props.exercise.otherOptions.map((option) => {
			return util.translateExpressionToPosition(
				option,
				props.exercise.position,
				props.maxKnown
			);
		});

		wrongPositions = arrayUtil.unique(wrongPositions);

		let otherOptions = wrongPositions.map((pos) => {
			return (
				<Button
					variant='outline-dark'
					onClick={props.onWrong}
					size='lg'
				>
					{pos}
				</Button>
			);
		});
		otherOptions.push(correctOption);
		let finalOptions = arrayUtil.shuffle(otherOptions);
		return finalOptions;
	}

	return (
		props.exercise && (
			<div className='exercise positionOfCard'>
				<div>Which position is this card at?</div>
				<div className='cardImage'>{renderCardImage()}</div>
				<div className='exerciseButtons'>{renderOptions()}</div>
			</div>
		)
	);
}

export default PositionOfCard;
