import stacksData from '../data/stacks.json';

const valueArray = [
	'A',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'J',
	'Q',
	'K',
];
const suitArray = ['D', 'C', 'S', 'H'];

const suitToSymbol = function (input) {
	return input
		.replace(' D', '♦️')
		.replace(' C', '♣️')
		.replace(' S', '♠️')
		.replace(' H', '♥️');
};

const getCardColor = function (input) {
	return input.indexOf('♦️') > -1 ||
		input.indexOf('♥️') > -1 ||
		input.indexOf('D') > -1 ||
		input.indexOf('H') > -1
		? 'red'
		: 'black';
};

const getImageFileName = function (input) {
	let cardArray = input.split(' ');
	let cardString = '';
	switch (cardArray[1]) {
		case 'S':
			cardString += 'spades';
			break;
		case 'H':
			cardString += 'hearts';
			break;
		case 'D':
			cardString += 'diamonds';
			break;
		case 'C':
			cardString += 'clubs';
			break;
		default:
			break;
	}
	cardString += '_';
	switch (cardArray[0]) {
		case 'A':
			cardString += 'ace';
			break;
		case 'J':
			cardString += 'jack';
			break;
		case 'Q':
			cardString += 'queen';
			break;
		case 'K':
			cardString += 'king';
			break;
		default:
			cardString += cardArray[0];
			break;
	}
	cardString += '.png';
	return cardString;
};

function translateExpressionToCard(expression, mainPosition, avoidPosition) {
	if (!isNaN(+expression)) {
		return stacksData.mnemonica[expression - 1];
	}
	let mainCard = stacksData.mnemonica[mainPosition - 1];
	let avoidCard = avoidPosition
		? stacksData.mnemonica[avoidPosition - 1]
		: '';
	let valueChar = expression[0];
	let value = 'A';
	switch (valueChar) {
		case '*':
			value = valueArray[Math.floor(Math.random() * valueArray.length)];
			break;
		case 'n':
			value = mainCard.split(' ')[0];
			break;
		default:
			value = valueArray[Math.floor(Math.random() * valueArray.length)];
			break;
	}
	let suitChar = expression[1];
	let suit = 'C';
	switch (suitChar) {
		case '*':
			suit = suitArray[Math.floor(Math.random() * suitArray.length)];
			break;
		case 's':
			suit = mainCard.split(' ')[1];
			break;
		default:
			suit = suitArray[Math.floor(Math.random() * suitArray.length)];
			break;
	}
	let optionCard = value + ' ' + suit;

	if (optionCard === mainCard || optionCard === avoidCard) {
		return translateExpressionToCard(
			expression,
			mainPosition,
			avoidPosition
		);
	}
	return optionCard;
}

function translateExpressionToPosition(expression, mainPosition, maxKnown) {
	if (!isNaN(+expression)) {
		return +expression;
	}
	let position = 1;
	switch (expression) {
		case '*':
			position = Math.floor(Math.random() * maxKnown) + 1;
			break;
		default:
			position = Math.floor(Math.random() * maxKnown) + 1;
			break;
	}
	if (position === mainPosition) {
		return translateExpressionToPosition(
			expression,
			mainPosition,
			maxKnown
		);
	}
	return position;
}

export {
	suitToSymbol,
	getCardColor,
	getImageFileName,
	translateExpressionToCard,
	translateExpressionToPosition,
};
