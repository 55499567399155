import { useEffect, useState } from 'react';
import './App.scss';
import Head from './components/head/head';
import LessonList from './components/lessonList/lessonList';
import WelcomeMessage from './components/welcomeMessage/welcomeMessage';
import {
	StreakReset,
	StreakIncreased,
} from './components/streakMessages/streakMessages';
import Info from './components/info/info';
import * as DateUtil from './util/dateUtils';

function App() {
	const [progress, setProgress] = useState(null);
	const [showWelcome, setShowWelcome] = useState(false);
	const [showInfo, setShowInfo] = useState(false);
	const [showStreakReset, setShowStreakReset] = useState(false);
	const [showStreakIncreased, setShowStreakIncreased] = useState(false);

	useEffect(() => {
		let data = localStorage.getItem('progress-mnemonica');
		let userProgress = null;
		if (data) {
			//convert to json and store in state
			userProgress = JSON.parse(data);

			// temp code to catch up old users
			if (userProgress.streak === undefined) {
				// temp code to catch up old users
				userProgress.streak = 0;
			}
			if (userProgress.strengths === undefined) {
				userProgress.strengths = [];
				for (
					let index = 1;
					index <= userProgress.lessonsCompleted;
					index++
				) {
					userProgress.strengths.push({
						lesson: index,
						strength: 50,
						lastPracticed: new Date(),
						lastUpdated: new Date(),
					});
				}
			}
			if (userProgress.checkpointsCompleted == undefined) {
				userProgress.checkpointsCompleted = 0;
			}

			// DECREASE STRENGTH ON LOAD ONCE A DAY
			let nowDate = new Date();
			userProgress.strengths = userProgress.strengths.map((strength) => {
				let daysSincePractice = DateUtil.datediff(
					new Date(strength.lastPracticed).setHours(0, 0, 0, 0),
					nowDate.setHours(0, 0, 0, 0)
				);
				let daysSinceUpdate = DateUtil.datediff(
					new Date(strength.lastUpdated).setHours(0, 0, 0, 0),
					nowDate.setHours(0, 0, 0, 0)
				);
				let shouldDecrease =
					daysSinceUpdate > 3 && daysSincePractice > 3;
				let decreaseAmount = shouldDecrease
					? Math.floor(daysSinceUpdate / 3) * 5
					: 0;
				let newStrength = strength.strength - decreaseAmount;
				if (newStrength < 5) newStrength = 5;
				return {
					lesson: strength.lesson,
					strength: newStrength,
					lastPracticed: strength.lastPracticed,
					lastUpdated: shouldDecrease
						? nowDate
						: strength.lastUpdated,
				};
			});
			localStorage.setItem(
				'progress-mnemonica',
				JSON.stringify(userProgress)
			);

			if (
				userProgress.streak > 0 &&
				DateUtil.datediff(
					new Date(userProgress.lastPracticed).setHours(0, 0, 0, 0),
					new Date().setHours(0, 0, 0, 0)
				) > 1
			) {
				userProgress.streak = 0;
				localStorage.setItem(
					'progress-mnemonica',
					JSON.stringify(userProgress)
				);
				setShowStreakReset(true);
			}
		} else {
			//create new item and store and store in state
			let newUser = {
				lessonsCompleted: 0,
				lastPracticed: new Date(),
				streak: 0,
				strengths: [],
				checkpointsCompleted: 0,
			};
			localStorage.setItem('progress-mnemonica', JSON.stringify(newUser));
			userProgress = newUser;
			setShowWelcome(true);
		}
		setProgress(userProgress);
	}, ['']);

	function updateProgress(
		lessonCompleted,
		lastPracticed,
		isSuccess,
		isPractice,
		isCheckpoint
	) {
		let newProgress = JSON.parse(JSON.stringify(progress));
		if (
			!isCheckpoint &&
			!isPractice &&
			isSuccess &&
			lessonCompleted > newProgress.lessonsCompleted
		) {
			//lesson completed for the first time
			newProgress.lessonsCompleted = lessonCompleted;
			newProgress.strengths.push({
				lesson: lessonCompleted,
				strength: 50,
				lastPracticed: new Date(),
				lastUpdated: new Date(),
			});
		}
		if (isSuccess && isPractice) {
			if (lessonCompleted === 42) {
				//full practice success
				for (
					let index = 1;
					index <= newProgress.lessonsCompleted;
					index++
				) {
					let strengthIndex = newProgress.strengths.findIndex(
						(obj) => obj.lesson == index
					);
					let newStrength =
						newProgress.strengths[strengthIndex].strength + 5;
					if (newStrength > 100) newStrength = 100;
					newProgress.strengths[strengthIndex].strength = newStrength;
					newProgress.strengths[strengthIndex].lastPracticed =
						new Date();
					newProgress.strengths[strengthIndex].lastUpdated =
						new Date();
				}
			} else {
				//practice success
				let strengthIndex = newProgress.strengths.findIndex(
					(obj) => obj.lesson == lessonCompleted
				);
				let newStrength =
					newProgress.strengths[strengthIndex].strength + 10;
				if (newStrength > 100) newStrength = 100;
				newProgress.strengths[strengthIndex].strength = newStrength;
				newProgress.strengths[strengthIndex].lastPracticed = new Date();
				newProgress.strengths[strengthIndex].lastUpdated = new Date();
			}
		}
		if (isSuccess && isCheckpoint) {
			//checkpoint success
			newProgress.checkpointsCompleted = lessonCompleted;
		}
		if (
			//lesson relearn success
			isSuccess &&
			new Date(progress.lastPracticed).setHours(0, 0, 0, 0) <
				new Date().setHours(0, 0, 0, 0)
		) {
			newProgress.streak += 1;
			setShowStreakIncreased(true);
		}
		newProgress.lastPracticed = lastPracticed;
		localStorage.setItem('progress-mnemonica', JSON.stringify(newProgress));
		setProgress(newProgress);
	}

	function onCloseWelcome() {
		setShowWelcome(false);
	}

	function openInfo() {
		setShowWelcome(false);
		setShowInfo(true);
	}

	function closeInfo() {
		setShowInfo(false);
	}

	function onCloseStreak() {
		setShowStreakReset(false);
		setShowStreakIncreased(false);
	}

	return (
		<div className='App'>
			{progress && <Head progress={progress} openInfo={openInfo} />}
			<div className='body'>
				{!showWelcome &&
					!showInfo &&
					!showStreakReset &&
					!showStreakIncreased && (
						<LessonList
							progress={progress}
							updateProgress={updateProgress}
						/>
					)}
				{showWelcome && <WelcomeMessage onFinish={onCloseWelcome} />}
				{showInfo && <Info onClose={closeInfo} />}
				{showStreakReset && <StreakReset onFinish={onCloseStreak} />}
				{showStreakIncreased && (
					<StreakIncreased
						onFinish={onCloseStreak}
						progress={progress}
					/>
				)}
			</div>
		</div>
	);
}

export default App;
