import './streakMessages.scss';

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
let SadCat = require('../../gifs/sadcat.gif');
let Streak = require('../../gifs/streak.gif');

function StreakReset(props) {
	return (
		<div className='streakReset'>
			<div className='streakResetWrapper'>
				<h2>Oh no!</h2>
				<p>Your streak has been reset...</p>
				<img src={SadCat} />
				<p>No worries. Time to start a new streak!</p>
				<Button variant='primary' size='lg' onClick={props.onFinish}>
					Let's learn!
				</Button>
			</div>
		</div>
	);
}

function StreakIncreased(props) {
	return (
		props.progress && (
			<div className='streakIncreased'>
				<div className='streakIncreasedWrapper'>
					<h2>Yeah!</h2>
					<p>You're on a {props.progress.streak} day streak!</p>
					<img src={Streak} />
					<Button
						variant='primary'
						size='lg'
						onClick={props.onFinish}
					>
						Yay!
					</Button>
				</div>
			</div>
		)
	);
}

export { StreakReset, StreakIncreased };
