import './exercise.scss';
import stacksData from '../../data/stacks.json';
import * as util from '../../util/cardTranslation';
import * as arrayUtil from '../../util/arrayUtils';

function CardAfter(props) {
	function renderCardImage() {
		let card =
			props.exercise.position - 2 < 0
				? stacksData.mnemonica[51]
				: stacksData.mnemonica[props.exercise.position - 2];
		let imgFile = util.getImageFileName(card);
		return (
			<img
				alt=''
				key={imgFile}
				src={require('../../images/' + imgFile)}
			/>
		);
	}

	function renderOptions() {
		let correctImgFile = util.getImageFileName(
			stacksData.mnemonica[props.exercise.position - 1]
		);
		let correctOption = (
			<img
				alt=''
				key={correctImgFile}
				src={require('../../images/' + correctImgFile)}
				onClick={props.onCorrect}
			/>
		);

		let wrongCards = props.exercise.otherOptions.map((option) => {
			return util.translateExpressionToCard(
				option,
				props.exercise.position,
				props.exercise.position - 1
			);
		});
		wrongCards = arrayUtil.unique(wrongCards);

		let otherOptions = wrongCards.map((card) => {
			let imgFile = util.getImageFileName(card);
			return (
				<img
					alt=''
					key={imgFile}
					src={require('../../images/' + imgFile)}
					onClick={props.onWrong}
				/>
			);
		});
		otherOptions.push(correctOption);
		let finalOptions = arrayUtil.shuffle(otherOptions);
		return finalOptions;
	}

	return (
		props.exercise && (
			<div className='exercise cardAfter'>
				<div>Which card is after this card?</div>
				<div className='cardImage'>{renderCardImage()}</div>
				<div className='exerciseButtons'>{renderOptions()}</div>
			</div>
		)
	);
}

export default CardAfter;
