import './lessonList.scss';
import stacksData from '../../data/stacks.json';
import lessonsData from '../../data/lessons.json';
import * as util from '../../util/cardTranslation';
import { useState } from 'react';
import Lesson from '../lesson/lesson';
import Practice from '../lesson/practice';
import Checkpoint from '../checkpoint/checkpoint';
import Button from 'react-bootstrap/Button';
import { Lock } from 'react-bootstrap-icons';
import ProgressBar from '@ramonak/react-progress-bar';

function LessonList(props) {
	const [currentLesson, setCurrentLesson] = useState(0);
	const [currentPractice, setCurrentPractice] = useState(0);
	const [currentCheckpoint, setCurrentCheckpoint] = useState(0);

	function renderFullPractice() {
		if (!props.progress) {
			return null;
		}
		let fullPracticeUnlocked = props.progress.lessonsCompleted >= 2;
		return (
			fullPracticeUnlocked && (
				<div className={'fullPracticeListItem'} key={42}>
					<div className='fullPracticeActions'>
						<Button
							onClick={() =>
								fullPracticeUnlocked && practiceClick(42)
							}
							variant={'success'}
						>
							{'Full Practice'}
						</Button>
					</div>
				</div>
			)
		);
	}

	function renderLessons() {
		let lessonRenders = lessonsData.map((element) => {
			return renderLesson(element.number, element.start);
		});
		return (
			<>
				{renderFullPractice()}
				{lessonRenders}
			</>
		);
	}

	function renderLesson(lessonNumber, start) {
		if (!props.progress) {
			return null;
		}

		if (lessonNumber === 0) {
			return renderCheckpointTest(start);
		}

		let startIndex = start - 1;
		let lessonCards = [];
		for (let index = startIndex; index < startIndex + 4; index++) {
			lessonCards.push(util.suitToSymbol(stacksData.mnemonica[index]));
		}
		let cardSpans = lessonCards.map((element) => {
			let colorClass = util.getCardColor(element);
			return (
				<span key={element} className={colorClass}>
					{element}
				</span>
			);
		});

		let lessonUnlocked =
			props.progress.lessonsCompleted >= lessonNumber - 1;
		switch (lessonNumber) {
			case 5:
				if (props.progress.checkpointsCompleted < 1) {
					lessonUnlocked = false;
				}
				break;
			case 8:
				if (props.progress.checkpointsCompleted < 2) {
					lessonUnlocked = false;
				}
				break;
			case 11:
				if (props.progress.checkpointsCompleted < 3) {
					lessonUnlocked = false;
				}
				break;
			default:
				break;
		}

		let lessonClass = lessonUnlocked ? 'unlocked' : 'locked';
		let practiceUnlocked = props.progress.lessonsCompleted >= lessonNumber;
		let practiceClass =
			practiceUnlocked || !lessonUnlocked ? 'unlocked' : 'locked';

		let strengthIndex = props.progress.strengths.findIndex(
			(obj) => obj.lesson == lessonNumber
		);
		let strength =
			strengthIndex > -1
				? props.progress.strengths[strengthIndex].strength
				: 0;
		let strengthColor =
			strength > 49 ? '#4DA167' : strength > 24 ? 'orange' : '#FF0022';

		return (
			<div className={'lessonListItem ' + lessonClass} key={lessonNumber}>
				<div className='lessonDetails'>
					<div className='title'>Lesson {lessonNumber}</div>
					{lessonUnlocked && <div className='cards'>{cardSpans}</div>}
				</div>
				{lessonUnlocked && (
					<div className='lessonActions'>
						<Button
							onClick={() =>
								lessonUnlocked && learnClick(lessonNumber)
							}
							variant={practiceUnlocked ? 'secondary' : 'primary'}
						>
							{practiceUnlocked ? 'Re-learn' : 'Learn'}
						</Button>
						{practiceUnlocked && (
							<Button
								className={practiceClass}
								onClick={() =>
									lessonUnlocked &&
									practiceUnlocked &&
									practiceClick(lessonNumber)
								}
								variant='success'
							>
								Practice
							</Button>
						)}
					</div>
				)}
				{practiceUnlocked && (
					<div className='strengthWrapper'>
						<ProgressBar
							completed={strength}
							className='strengthMeter'
							isLabelVisible={false}
							bgColor={strengthColor}
							height={'4px'}
						/>
					</div>
				)}
				{!lessonUnlocked && (
					<div className='lockedIcon'>
						<Lock color='#1A1A1A' size='20' />
					</div>
				)}
			</div>
		);
	}

	function renderCheckpointTest(maxLesson) {
		let checkpointUnlocked = props.progress.lessonsCompleted >= maxLesson;
		let checkpointClass = checkpointUnlocked ? 'unlocked' : 'locked';
		return (
			<div
				className={'checkpointListItem ' + checkpointClass}
				key={maxLesson + 666}
			>
				<div className='checkpointDetails'>
					<div className='title'>Checkpoint Test</div>
					<div className='lessons-span'>Lessons 1 - {maxLesson}</div>
				</div>
				{checkpointUnlocked && (
					<div className='checkpointActions'>
						<Button
							onClick={() =>
								checkpointUnlocked && checkpointClick(maxLesson)
							}
							variant={'primary'}
						>
							{'Test'}
						</Button>
					</div>
				)}
				{!checkpointUnlocked && (
					<div className='lockedIcon'>
						<Lock color='#1A1A1A' size='20' />
					</div>
				)}
			</div>
		);
	}

	function onLessonClose() {
		setCurrentLesson(0);
	}

	function onLessonComplete(isSuccess) {
		props.updateProgress(
			+currentLesson,
			new Date(),
			isSuccess,
			false,
			false
		);
		setCurrentLesson(0);
	}

	function onPracticeClose() {
		setCurrentPractice(0);
	}

	function onPracticeComplete() {
		props.updateProgress(+currentPractice, new Date(), true, true, false);
		setCurrentPractice(0);
	}

	function onCheckpointClose() {
		setCurrentCheckpoint(0);
	}

	function onCheckpointComplete() {
		let checkpointNumber = 1;
		switch (currentCheckpoint) {
			case 4:
				checkpointNumber = 1;
				break;
			case 7:
				checkpointNumber = 2;
				break;
			case 10:
				checkpointNumber = 3;
				break;
			case 13:
				checkpointNumber = 4;
				break;
			default:
				break;
		}
		props.updateProgress(+checkpointNumber, new Date(), true, false, true);
		setCurrentCheckpoint(0);
	}

	function renderLessonContent() {
		let currentLessonObject = lessonsData.find((element) => {
			return element.number === currentLesson;
		});

		return (
			<Lesson
				lesson={currentLessonObject}
				onClose={onLessonClose}
				onComplete={onLessonComplete}
				progress={props.progress}
			/>
		);
	}

	function renderPracticeContent() {
		let allowAfter = currentPractice < props.progress.lessonsCompleted;
		let allowBefore = currentPractice !== 1;
		return (
			<Practice
				lessonNumber={currentPractice}
				onClose={onPracticeClose}
				onComplete={onPracticeComplete}
				allowAfter={allowAfter}
				allowBefore={allowBefore}
				progress={props.progress}
			/>
		);
	}

	function renderCheckpointContent() {
		let checkpointNumber = 1;
		switch (currentCheckpoint) {
			case 4:
				checkpointNumber = 1;
				break;
			case 7:
				checkpointNumber = 2;
				break;
			case 10:
				checkpointNumber = 3;
				break;
			case 13:
				checkpointNumber = 4;
				break;
			default:
				break;
		}
		return (
			<Checkpoint
				checkpointNumber={checkpointNumber}
				onClose={onCheckpointClose}
				onComplete={onCheckpointComplete}
				progress={props.progress}
			/>
		);
	}

	function learnClick(lessonNumber) {
		setCurrentLesson(lessonNumber);
	}

	function practiceClick(lessonNumber) {
		setCurrentPractice(lessonNumber);
	}

	function checkpointClick(maxLesson) {
		setCurrentCheckpoint(maxLesson);
	}

	return (
		<div className='lessons'>
			{currentLesson === 0 &&
				currentPractice === 0 &&
				currentCheckpoint === 0 &&
				renderLessons()}
			{currentLesson !== 0 && renderLessonContent()}
			{currentPractice !== 0 && renderPracticeContent()}
			{currentCheckpoint !== 0 && renderCheckpointContent()}
		</div>
	);
}

export default LessonList;
