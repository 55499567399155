import lessonsData from '../data/lessons.json';
import * as util from './arrayUtils.js';

const generateLessonPracticeExercises = function (
	lessonNumber,
	allowBefore,
	allowAfter
) {
	let exerciseCount = 10;
	let exercises = [];

	let currentLessonObject = lessonsData.find((element) => {
		return element.number === lessonNumber;
	});
	let cardIndices = [];
	let minPosition = currentLessonObject.start;
	let maxPosition = currentLessonObject.start + 3;
	for (let index = minPosition; index <= maxPosition; index++) {
		cardIndices.push(index);
	}

	let typeArray = [
		'cardAtPosition',
		'positionOfCard',
		'cardBefore',
		'cardAfter',
	];

	//generate 10 random exercises for the cards in the lessons
	for (let index = 0; index < exerciseCount; index++) {
		let cardIndex = util.random(cardIndices);
		let exerciseType = util.random(typeArray);
		if (
			cardIndex === maxPosition &&
			exerciseType === 'cardBefore' &&
			!allowAfter
		) {
			exerciseType = 'cardAfter';
		}
		if (
			cardIndex === minPosition &&
			exerciseType === 'cardAfter' &&
			!allowBefore
		) {
			exerciseType = 'cardBefore';
		}
		let closeOption = util.random(cardIndices);
		while (
			closeOption === cardIndex ||
			(closeOption === cardIndex + 1 && exerciseType === 'cardBefore') ||
			(closeOption === cardIndex - 1 && exerciseType === 'cardAfter')
		) {
			closeOption = util.random(cardIndices);
		}
		let exercise = {
			type: exerciseType,
			position: cardIndex,
			otherOptions: ['n*', '*s', '**', closeOption.toString()],
		};
		exercises.push(exercise);
	}

	//generate 2 for cards before
	if (allowBefore) {
		let beforeIndices = [];
		for (let index = 1; index < minPosition; index++) {
			beforeIndices.push(index);
		}
		for (let index = 0; index < 2; index++) {
			let cardIndex = util.random(beforeIndices);
			let exerciseType = util.random(typeArray);
			if (cardIndex === 1 && exerciseType === 'cardAfter') {
				exerciseType = 'cardBefore';
			}
			let closeOption = util.random(beforeIndices);
			while (
				closeOption === cardIndex ||
				(closeOption === cardIndex + 1 &&
					exerciseType === 'cardBefore') ||
				(closeOption === cardIndex - 1 && exerciseType === 'cardAfter')
			) {
				closeOption = util.random(beforeIndices);
			}
			let exercise = {
				type: exerciseType,
				position: cardIndex,
				otherOptions: ['n*', '*s', '**', closeOption.toString()],
			};
			exercises.push(exercise);
		}
	}

	//generate 2 for cards after
	if (allowAfter) {
		let afterIndices = [];
		for (let index = maxPosition + 1; index < maxPosition + 5; index++) {
			afterIndices.push(index);
		}
		for (let index = 0; index < 2; index++) {
			let cardIndex = util.random(afterIndices);
			let exerciseType = util.random(typeArray);
			if (
				cardIndex === maxPosition + 4 &&
				exerciseType === 'cardBefore'
			) {
				exerciseType = 'cardAfter';
			}
			let closeOption = util.random(afterIndices);
			while (
				closeOption === cardIndex ||
				(closeOption === cardIndex + 1 &&
					exerciseType === 'cardBefore') ||
				(closeOption === cardIndex - 1 && exerciseType === 'cardAfter')
			) {
				closeOption = util.random(afterIndices);
			}
			let exercise = {
				type: exerciseType,
				position: cardIndex,
				otherOptions: ['n*', '*s', '**', closeOption.toString()],
			};
			exercises.push(exercise);
		}
	}

	return util.shuffle(exercises);
};

const generateFullPracticeExercises = function (lessonsCompleted) {
	let exerciseCount = 20;
	let exercises = [];

	let cardIndices = [];
	let minPosition = 1;
	let maxPosition = lessonsCompleted * 4;
	for (let index = minPosition; index <= maxPosition; index++) {
		cardIndices.push(index);
	}

	let typeArray = [
		'cardAtPosition',
		'positionOfCard',
		'cardBefore',
		'cardAfter',
	];

	//generate 10 random exercises for the cards in the lessons
	for (let index = 0; index < exerciseCount; index++) {
		let cardIndex = util.random(cardIndices);
		let exerciseType = util.random(typeArray);
		if (cardIndex === maxPosition && exerciseType === 'cardBefore') {
			exerciseType = 'cardAfter';
		}
		if (cardIndex === minPosition && exerciseType === 'cardAfter') {
			exerciseType = 'cardBefore';
		}
		let closeOption = util.random(cardIndices);
		while (
			closeOption === cardIndex ||
			(closeOption === cardIndex + 1 && exerciseType === 'cardBefore') ||
			(closeOption === cardIndex - 1 && exerciseType === 'cardAfter')
		) {
			closeOption = util.random(cardIndices);
		}
		let exercise = {
			type: exerciseType,
			position: cardIndex,
			otherOptions: ['n*', '*s', '**', closeOption.toString()],
		};
		exercises.push(exercise);
	}
	console.log(exercises);
	return util.shuffle(exercises);
};

export { generateLessonPracticeExercises, generateFullPracticeExercises };
