import * as util from './arrayUtils.js';

const generateCheckpointExercises = function (checkpointNumber) {
	let exerciseCount = 0;
	let exercises = [];
	let cardIndices = [];
	let minPosition = 1;
	let maxPosition = 1;
	switch (checkpointNumber) {
		case 1:
			maxPosition = 16;
			break;
		case 1:
			maxPosition = 28;
			break;
		case 1:
			maxPosition = 40;
			break;
		case 1:
			maxPosition = 52;
			break;
		default:
			break;
	}
	exerciseCount = maxPosition;

	for (let index = minPosition; index <= maxPosition; index++) {
		cardIndices.push(index);
	}

	let typeArray = [
		'cardAtPosition',
		'positionOfCard',
		'cardBefore',
		'cardAfter',
	];

	//generate 10 random exercises for the cards in the lessons
	for (let index = 0; index < maxPosition; index++) {
		let cardIndex = index + 1;
		let exerciseType = util.random(typeArray);
		if (cardIndex === maxPosition && exerciseType === 'cardBefore') {
			exerciseType = 'cardAfter';
		}
		if (cardIndex === minPosition && exerciseType === 'cardAfter') {
			exerciseType = 'cardBefore';
		}
		let closeOption = util.random(cardIndices);
		while (
			closeOption === cardIndex ||
			(closeOption === cardIndex + 1 && exerciseType === 'cardBefore') ||
			(closeOption === cardIndex - 1 && exerciseType === 'cardAfter')
		) {
			closeOption = util.random(cardIndices);
		}
		let exercise = {
			type: exerciseType,
			position: cardIndex,
			otherOptions: ['n*', '*s', '**', closeOption.toString()],
		};
		exercises.push(exercise);
	}

	return util.shuffle(exercises);
};

export { generateCheckpointExercises };
