import './head.scss';
import { Fire, InfoCircleFill, ChatLeftHeart } from 'react-bootstrap-icons';

function giveFeedback(e) {
	window.location.href =
		"mailto:me@zachwerden.com?subject=Mnemonica%20App%20Feedback&body=I've%20been%20using%20your%20Mnemonica%20app%20and%20have%20the%20following%20feedback%3A";
	e.preventDefault();
}

function Head(props) {
	if (!props.progress) return null;
	let hasPracticedToday =
		new Date(props.progress.lastPracticed).setHours(0, 0, 0, 0) ===
		new Date().setHours(0, 0, 0, 0);
	let streakColor = hasPracticedToday ? '#E17E0E' : '#1A1A1A';
	return (
		props.progress && (
			<div className='header'>
				<div className='streak'>
					<Fire color={streakColor} size='16' />
					{props.progress.streak ?? 0}
				</div>
				<div className='title'>MNEMONICA TRAINER</div>
				<div className='info'>
					<ChatLeftHeart
						color='#1A1A1A'
						size='16'
						onClick={giveFeedback}
					/>
					<InfoCircleFill
						color='#1A1A1A'
						size='16'
						onClick={props.openInfo}
					/>
				</div>
			</div>
		)
	);
}

export default Head;
