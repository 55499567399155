import './welcomeMessage.scss';

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';

function WelcomeMessage(props) {
	return (
		<div className='welcome'>
			<div className='welcomeWrapper'>
				<p>Welcome to my stacked deck trainer!</p>
				<p>
					This is an app designed to help you learn and memorize the
					Mnemonica stack. It's design is inspired by the Duolingo app
					that is used for learning languages.
				</p>
				<p>
					We store your progress in your browser's localstorage. This
					means that if you transfer devices or try loading this on a
					different device, your progress will be lost.
				</p>
				<Button variant='primary' size='lg' onClick={props.onFinish}>
					Let's learn!
				</Button>
			</div>
		</div>
	);
}

export default WelcomeMessage;
