import './exercise.scss';
import stacksData from '../../data/stacks.json';
import * as util from '../../util/cardTranslation';
import * as arrayUtil from '../../util/arrayUtils';

function CardAtPosition(props) {
	function renderOptions() {
		let correctImgFile = util.getImageFileName(
			stacksData.mnemonica[props.exercise.position - 1]
		);
		let correctOption = (
			<img
				key={correctImgFile}
				src={require('../../images/' + correctImgFile)}
				onClick={props.onCorrect}
			/>
		);

		let wrongCards = props.exercise.otherOptions.map((option) => {
			return util.translateExpressionToCard(
				option,
				props.exercise.position
			);
		});
		wrongCards = arrayUtil.unique(wrongCards);

		let otherOptions = wrongCards.map((card) => {
			let imgFile = util.getImageFileName(card);
			return (
				<img
					key={imgFile}
					src={require('../../images/' + imgFile)}
					onClick={props.onWrong}
				/>
			);
		});
		otherOptions.push(correctOption);
		let finalOptions = arrayUtil.shuffle(otherOptions);
		return finalOptions;
	}

	return (
		props.exercise && (
			<div className='exercise cardAtPosition'>
				<div>
					Which card is at position{' '}
					<strong>{props.exercise.position}</strong>?
				</div>
				<div className='exerciseButtons'>{renderOptions()}</div>
			</div>
		)
	);
}

export default CardAtPosition;
