import './exercise.scss';
import stacksData from '../../data/stacks.json';
import * as util from '../../util/cardTranslation';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';

function Info(props) {
	const [showButton, setShowButton] = useState(false);
	let timer1 = setTimeout(() => setShowButton(true), 1000);
	useEffect(() => {
		return () => clearTimeout(timer1);
	}, [showButton]);

	function getCard(position) {
		let card = util.suitToSymbol(stacksData.mnemonica[position - 1]);
		let colorClass = util.getCardColor(card);
		return <span className={colorClass}>{card}</span>;
	}

	function renderBasicText(position) {
		if (position == 1) {
			return (
				<div className='additionalText'>
					This is the first card. Or, in a face down deck, it is on
					top of the deck.
				</div>
			);
		}
		let cardBefore = getCard(position - 1);
		return (
			<div className='additionalText'>
				This means it is after the {cardBefore}. Or, in a face down
				deck, it is {position} from the top.
			</div>
		);
	}

	function renderAdditionalText() {
		if (props.exercise.additionalText) {
			return (
				<div className='additionalText'>
					{props.exercise.additionalText}
				</div>
			);
		}
		return null;
	}

	return (
		props.exercise && (
			<div className='exercise info'>
				<div>
					The card at position {props.exercise.position} is{' '}
					{getCard(props.exercise.position)}.
				</div>
				<div className='positionCard'>
					{props.exercise.position} =&nbsp;
					<img
						src={require('../../images/' +
							util.getImageFileName(
								stacksData.mnemonica[
									props.exercise.position - 1
								]
							))}
					/>
				</div>
				{renderBasicText(props.exercise.position)}
				{renderAdditionalText()}
				<div className='exerciseButtons d-grid gap-2'>
					{showButton && (
						<Button
							variant='primary'
							size='lg'
							onClick={props.onFinish}
						>
							Got It!
						</Button>
					)}
				</div>
			</div>
		)
	);
}

export default Info;
