import lessonsData from '../data/lessons.json';
import * as util from './arrayUtils.js';

const generateLessonExercises = function (lessonNumber) {
	let exercises = [];

	let currentLessonObject = lessonsData.find((element) => {
		return element.number === lessonNumber;
	});
	let cardIndices = [];
	let minPosition = currentLessonObject.start;
	let maxPosition = currentLessonObject.start + 3;
	for (let index = minPosition; index <= maxPosition; index++) {
		cardIndices.push(index);
	}

	let typeArray = [
		'cardAtPosition',
		'positionOfCard',
		'cardBefore',
		'cardAfter',
	];

	//card 1
	let cardPosition = minPosition;
	exercises.push({
		type: 'info',
		position: cardPosition,
		additionalText: '',
	});
	exercises.push({
		type: 'cardAtPosition',
		position: cardPosition,
		otherOptions: ['n*', '*s', '**', (cardPosition - 1).toString()],
	});
	exercises.push({
		type: 'positionOfCard',
		position: cardPosition,
		otherOptions: [
			(cardPosition + 2).toString(),
			(cardPosition + 1).toString(),
			(cardPosition - 1).toString(),
		],
	});
	exercises.push({
		type: 'cardAfter',
		position: cardPosition,
		otherOptions: [
			'**',
			(cardPosition + 1).toString(),
			(cardPosition - 2).toString(),
			'**',
		],
	});

	//card 2
	cardPosition = minPosition + 1;
	exercises.push({
		type: 'info',
		position: cardPosition,
		additionalText: '',
	});
	//TODO: randomize this to random position before?
	exercises.push({
		type: 'cardAtPosition',
		position: cardPosition - 1,
		otherOptions: ['n*', '*s', '**', cardPosition.toString()],
	});
	exercises.push({
		type: 'cardAtPosition',
		position: cardPosition,
		otherOptions: ['n*', '*s', '**', (cardPosition - 1).toString()],
	});
	exercises.push({
		type: 'cardAfter',
		position: cardPosition,
		otherOptions: [
			'**',
			(cardPosition + 1).toString(),
			(cardPosition - 2).toString(),
			'**',
		],
	});

	//card 3
	cardPosition = minPosition + 2;
	exercises.push({
		type: 'info',
		position: cardPosition,
		additionalText: '',
	});
	//TODO: randomize this to random position before?
	exercises.push({
		type: 'cardAfter',
		position: cardPosition,
		otherOptions: [
			'**',
			(cardPosition - 2).toString(),
			(cardPosition + 1).toString(),
			'**',
		],
	});
	exercises.push({
		type: 'cardAtPosition',
		position: cardPosition,
		otherOptions: ['n*', '*s', '**', (cardPosition - 1).toString()],
	});
	exercises.push({
		type: 'cardBefore',
		position: cardPosition - 2,
		otherOptions: [
			cardPosition.toString(),
			'**',
			(cardPosition - 3).toString(),
			'**',
		],
	});

	//card 4
	cardPosition = minPosition + 3;
	exercises.push({
		type: 'info',
		position: cardPosition,
		additionalText: '',
	});
	exercises.push({
		type: 'cardAfter',
		position: cardPosition - 1,
		otherOptions: [
			'**',
			(cardPosition - 3).toString(),
			cardPosition.toString(),
			'**',
		],
	});
	exercises.push({
		type: 'cardAfter',
		position: cardPosition,
		otherOptions: [
			'**',
			(cardPosition - 3).toString(),
			(cardPosition - 2).toString(),
			'**',
		],
	});
	exercises.push({
		type: 'positionOfCard',
		position: cardPosition,
		otherOptions: [
			(cardPosition - 3).toString(),
			(cardPosition - 2).toString(),
			(cardPosition - 1).toString(),
		],
	});

	//final exam
	let examExercises = [];
	examExercises.push({
		type: 'cardAtPosition',
		position: minPosition,
		otherOptions: [
			(minPosition + 1).toString(),
			(minPosition + 2).toString(),
			(minPosition + 3).toString(),
		],
	});
	examExercises.push({
		type: 'cardAtPosition',
		position: minPosition + 1,
		otherOptions: [
			minPosition.toString(),
			(minPosition + 2).toString(),
			(minPosition + 3).toString(),
		],
	});
	examExercises.push({
		type: 'cardAtPosition',
		position: minPosition + 2,
		otherOptions: [
			(minPosition + 1).toString(),
			minPosition.toString(),
			(minPosition + 3).toString(),
		],
	});
	examExercises.push({
		type: 'cardAtPosition',
		position: minPosition + 3,
		otherOptions: [
			(minPosition + 1).toString(),
			(minPosition + 2).toString(),
			minPosition.toString(),
		],
	});
	examExercises = util.shuffle(examExercises);
	exercises.push(...examExercises);

	return exercises;
};

export { generateLessonExercises };
