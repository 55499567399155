const shuffle = function (array) {
	let currentIndex = array.length,
		randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex != 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
};

function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

const unique = function (array) {
	var distinctValues = array.filter(onlyUnique);
	return distinctValues;
};

const random = function (array) {
	const randomNumber = Math.floor(Math.random() * array.length);
	return array[randomNumber];
};

export { shuffle, unique, random };
