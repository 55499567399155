import './info.scss';
import { useEffect, useState } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';

function giveFeedback(e) {
	window.location.href =
		"mailto:me@zachwerden.com?subject=Mnemonica%20App%20Feedback&body=I've%20been%20using%20your%20Mnemonica%20app%20and%20have%20the%20following%20feedback%3A";
	e.preventDefault();
}

function Info(props) {
	return (
		<div className='infoContainer'>
			<div className='infoWrapper'>
				<CloseButton id='closeBtn' onClick={props.onClose} />
				<h2>Welcome!</h2>
				<p>
					This is an app designed to help you learn and memorize the
					Mnemonica stack. It's design is inspired by the Duolingo app
					that is used for learning languages.
				</p>
				<p>
					The stack is broken down into 13 lessons. In each lesson,
					you will learn 4 cards of the stack.
				</p>
				<p>
					Once you've completed a lesson, you will have access to
					Practice mode for that lesson.
				</p>
				<p>
					Each completed lesson has a strength meter under it to
					reflect your strength on those cards. Completing practice
					mode for the lesson increases your strength. Going many days
					without practicing will decrease your strength.
				</p>
				<p>
					Every so often you'll need to pass a Checkpoint Test to
					progress to the next lesson.
				</p>
				<p>
					In the top left corner, you'll see your daily streak.
					Completing a Lesson or a Practice once per day will
					increment your streak. Missing a day will reset your streak.
				</p>
				<p>
					We store your progress in your browser's localstorage. This
					means that if you transfer devices or try loading this on a
					different device, your progress will be lost.
				</p>
				<h2>Feedback</h2>
				<p>
					Help me improve this app. Any feedback is greatly
					appreciated!
				</p>
				<Button onClick={giveFeedback} variant={'primary'}>
					Give Feedback
				</Button>
				<p>&nbsp;</p>
				<h2>Versions</h2>
				<p>
					0.4.0 - 05.01.23
					<br />
					Added full practice mode. This gives you 20 exercises across
					all learned lessons. Completing it contributes to ALL of
					your lessons strength.
				</p>
				<p>
					0.3.0 - 04.23.23
					<br />
					Added checkpoint tests and released all lessons.
				</p>
				<p>
					0.2.2 - 02.23.23
					<br />
					Releasing lessons 3 and 4.
				</p>
				<p>
					0.2.1 - 02.22.23
					<br />
					Added info section. Added sad cat streak reset message.
				</p>
				<p>
					0.2.0 - 02.21.23
					<br />
					Strength meters for each lesson. Completing a new lesson
					sets it to 50. Not practicing for a while decreases it.
					Practicing increases it.
				</p>
				<p>
					0.1.0
					<br />
					Initial App. 2 Lessons, Practice Mode, Saved Progress,
					etc...
				</p>
			</div>
		</div>
	);
}

export default Info;
